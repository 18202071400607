import Vue from 'vue'
import App from './App.vue'
//import LazyLoad from "./directives/LazyLoad";
import router from './router'
import i18n from './i18n'
import '@/assets/styles/index.css'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload);

const fixIdScrolling = {
    watch: {
        $route() {
            const currentRoute = this.$router.currentRoute;
            const idToScrollTo = currentRoute.hash;
            this.$nextTick(() => {
                if (idToScrollTo && document.querySelector(idToScrollTo)) {
                    document.querySelector(idToScrollTo).scrollIntoView({ behavior: 'smooth' });
                } else {
                    document.querySelector('#app').scrollIntoView();
                }
            });
        },
    },
};


Vue.config.productionTip = false

//Vue.directive("lazyload", LazyLoad);


new Vue({
    mixins: [fixIdScrolling],
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
