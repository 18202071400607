<template>
  <div
    id="locale"
    class="container mx-auto px-6 pb-8 pt-8 md:px-0"
  >
    <h2 class="text-black font-serif font-semibold tracking-wider text-2xl">cantùccio</h2>
    <p class="text-black font-serif tracking-wider text-lg">s. m. [dim. di canto]</p>
    <ol class="mt-4 list-decimal list-outside px-4 ml-px">
      <li class="text-black font-serif tracking-wider text-lg">Parte del pane tagliato nel canto, dove c’è più crosta; in alcuni luoghi, pane biscottato.</li>
      <li class="mt-4 text-black font-serif tracking-wider text-lg">Piccolo angolo interno di una stanza o anche di un mobile. Per estens., angolo o luogo riposto, nascondiglio: trovarsi un c. dove stare tranquillo o riporre qualcosa in qualche c. segreto.</li>
    </ol>

    <div class="flex flex-col lg:grid lg:grid-cols-2 lg:gap-x-8 mt-8">
      <div class="col-span-1 flex flex-col space-y-4 py-1">
        <img
          v-lazy="images[0]"
          :alt="this.$t('about.image1')"
        />
        <img
          v-lazy="images[1]"
          :alt="this.$t('about.image1')"
          class="hidden lg:block"
        />
      <!-- <PhotoGallery id="gallery" class="hidden lg:block"/> -->

      </div>
      <div class="flex flex-col justify-between">
        <div class="text-lg">
          <p class="mt-4 lg:mt-0 text-black font-serif tracking-wider text-lg">
            {{ $t('about.text1') }}
          </p>
          <p class="mt-4 lg:mt-6 xl:mt-8 text-black font-serif tracking-wider text-lg">
            {{ $t('about.text2') }}
          </p>
          <img
            class="mt-4 block lg:hidden"
            v-lazy="images[1]"
          >
          <!-- <PhotoGallery id="galleryM" class="mt-4 block lg:hidden"/> -->
          <p class="mt-4 lg:mt-6 xl:mt-8 text-black font-serif tracking-wider text-lg">
            {{ $t('about.text3') }}
          </p>
          <p class="mt-4 lg:mt-6 xl:mt-8 text-black font-serif tracking-wider text-lg">
            {{ $t('about.text4') }}
          </p>
          <p class="mt-4 lg:mt-6 xl:mt-8 text-black font-serif tracking-wider text-lg">
            {{ $t('about.text5') }}<br />{{ $t('about.text6') }}
          </p>
          <a
            href="https://www.bolognatoday.it/social/ristoranti-pesce-bologna-2020.html"
            target="_blank"
            class="block mt-8 text-black font-serif text-lg uppercase hover:underline"
          >
            {{ $t('about.sayAboutUs') }} ➣
          </a>
        </div>
        <div class="mt-8 xl:mt-0 flex flex-col space-y-4">
          <a
            :href="'/menu?lang=' + currentLang()"
            class="text-salmon-100 text-2xl uppercase hover:underline"
          >{{ $t("home.readMenu") }} ➣</a>
          <a
            :href="'/wines?lang=' + currentLang()"
            class="text-salmon-100 text-2xl uppercase hover:underline"
          >{{ $t("home.readWines") }} ➣</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import VueGallery from "vue-gallery";
//import ImageItem from "@/components/ImageItem.vue";
// import PhotoGallery from "@/components/PhotoGallery.vue";

export default {
  name: "Vocabulary",
  components: {
    //Gallery: VueGallery,
    //ImageItem,
    // PhotoGallery
  },
    methods: {
    changeLang(lang) {
      this.$root.$i18n.locale = lang;
    },
    currentLang() {
      return this.$root.$i18n.locale;
    },
  },
  data: function () {
    return {
      images: [
        "/img/outside.jpg",
        "/img/dish.jpg",
      ],
      index: 1,
    };
  },
};
</script>