<template>
  <div>
    <MessageBar />
    <div class="container mx-auto">
      <div class="mt-8 px-6 md:px-0 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 sm:gap-4">
        <ImageItem
          source="/img/paccheri.jpg"
          alt="Paccheri"
          wrapperClass=""
        />
                <ImageItem
          source="/img/tonno1.jpg"
          alt="Paccheri"
          wrapperClass=""
        />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MessageBar from "@/components/MessageBar.vue";
//import VueGallery from "vue-gallery";
import Footer from "@/components/Footer.vue";
import ImageItem from "@/components/ImageItem.vue";

export default {
  name: "Homepage",
  components: {
    //  Gallery: VueGallery,
    Footer,
    MessageBar,
    ImageItem,
  },
  data: function () {
    return {
      images: [
        "/img/dish.jpg",
        "/img/dish1.jpg",
        "/img/dish2.jpeg",
        "/img/dish3.jpeg",
      ],
      index: 1,
    };
  },
};
</script>

<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>