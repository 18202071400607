import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '../views/Homepage.vue'
// import Menu from '../views/Menu.vue'
// import Wines from '../views/Wines.vue'
import Gallery from '../views/Gallery.vue'

// import Contact from '../views/Contact.vue'
// import About from '../views/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Homepage
  },
  // {
  //   path: '/menu',
  //   name: 'Menu',
  //   component: Menu
  // },
  // {
  //   path: '/wines',
  //   name: 'Wines',
  //   component: Wines
  // },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }

})

export default router
