<template>
  <div id="app" class="md:px-4">
    <MenuBar />
    <router-view />
  </div>
</template>

<script>
import MenuBar from "@/components/MenuBar.vue";

export default {
  components: {
    MenuBar,
  },
  methods: {
    changeLang(lang) {
      this.$root.$i18n.locale = lang;
    },
  },
  created() {
    if (this.$route.query.lang) {
      this.changeLang(this.$route.query.lang);
    //  window._iub.csConfiguration.lang = 'en';
    }

  },
  mounted() {},
};
</script>