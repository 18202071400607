<template>
  <nav class="container mx-auto text-salmon-100">
    <div class="flex justify-between items-end px-4 mt-6">
      <a
        v-if="currentLang()==='it'"
        v-on:click="changeLang('en')"
        class="block cursor-pointer hover:underline font-sans"
      >EN</a>
      <a
        v-if="currentLang()==='en'"
        v-on:click="changeLang('it')"
        class="block cursor-pointer hover:underline font-sans"
      >IT</a>
      <div class="flex flex-col items-center font-sans">
        <span>▲</span>
        <span class="-mt-2">N</span>
      </div>

    </div>
    <div class="px-1 py-3 flex items-center w-full lg:text-2xl">
      <div class="w-full flex flex-col items-center">
        <router-link
          class="block hover:underline"
          :to="{ path: '/', hash: '#locale' }"
          active-class="font-bold cursor-default"
        >{{ $t('nav.about') }}</router-link>
        <hr class="border-salmon-100 w-full" />

        <router-link
          class="block"
          :to="{ path: '/', hash: '#contact' }"
          active-class="font-bold cursor-default"
        >{{ $t('nav.contact') }}</router-link>
      </div>
      <router-link
        to="/"
        class="mx-2 rotate lg:w-1/2"
      >
        <img
          v-lazy="logo"
          class="w-full object-contain"
        />
      </router-link>
      <div class="w-full flex flex-col items-center">
        <a
          class="block hover:underline"
          :href="'/menu?lang=' + currentLang()"
          active-class="font-bold cursor-default"
        >{{ $t('nav.menu') }}</a>
        <hr class="border-salmon-100 w-full" />
        <!-- <router-link
          class="block hover:underline lg:hidden"
           :to="{ path: '/', hash: '#galleryM' }"
          active-class="font-bold cursor-default"
        >{{ $t('nav.gallery') }}</router-link> -->
        <!-- <router-link
          class="hidden lg:block hover:underline"
           :to="{ path: '/', hash: '#gallery' }"
          active-class="font-bold cursor-default"
        >{{ $t('nav.gallery') }}</router-link> -->
         <a
          class="hover:underline"
          href="https://g.page/r/CR039DvSlJYrEAE/review"
          active-class="font-bold cursor-default"
        >{{ $t('nav.reviewUs') }}</a>
      </div>
    </div>
  </nav>
</template>

<script>
//import i18n  from "../i18n";
import router from "../router";

export default {
  name: "MenuBar",
  methods: {
    changeLang(lang) {
      this.$root.$i18n.locale = lang;
    },
    currentLang() {
      return this.$root.$i18n.locale;
    },
  },
  data: function() {
    return {
      logo: "/img/cantuccio-logo-main.png",
    }
  },
  computed: {
    isHome() {
      console.log(router.currentRoute.value.path);
      return router.currentRoute.value.path === "/";
    },
  },
  props: {},
};
</script>