<template>
  <div class="mt-3 md:mt-6 px-6 md:px-0 lg:flex lg:justify-between lg:space-x-8">
    <div class="space-y-4 lg:w-1/2">
      <div>
        <div class="flex items-center">
          <hr class="border-salmon-100 flex-1" />
          <h1 class="px-4 font-serif capitalize text-salmon-100 text-2xl text-center flex-none">{{ $t('contact.openingTimes')}}</h1>
          <hr class="border-salmon-100 flex-1" />
        </div>
        <div class="mt-4 px-1 text-lg">
          <!-- <p class="text-justify">{{ $t('contact.introOpening')}}</p> -->
          <div class="flex justify-between items-start">
            <p>{{ $t('contact.monday')}}</p>
            <p class="flex-shrink-0 text-right">{{ $t('contact.closed')}}</p>
          </div>
          <div class="flex justify-between items-start mt-3">
            <p>{{ $t('contact.tuesday')}}</p>
            <p class="flex-shrink-0 text-right">19:30 - 22:30</p>
          </div>
          <div class="flex justify-between items-start mt-3">
            <p >{{ $t('contact.wednesday')}} - {{ $t('contact.thursday')}}</p>
            <div>
              <p class="flex-shrink-0 text-right">12:00 - 15:00</p>
              <p class="flex-shrink-0 text-right">19:30 - 22:30</p>
            </div>
          </div>
          <div class="flex justify-between items-start mt-3">
            <p >{{ $t('contact.friday')}} - {{ $t('contact.saturday')}}</p>
            <div>
              <p class="flex-shrink-0 text-right">12:00 - 15:00</p>
              <p class="flex-shrink-0 text-right">19:15 - 23:00</p>
            </div>
          </div>
          <div class="flex justify-between items-start mt-3">
            <p >{{ $t('contact.sunday')}}</p>
            <div>
              <p class="flex-shrink-0 text-right">12:30 - 15:30</p>
              <p class="flex-shrink-0 text-right">19:30 - 22:30</p>
            </div>
          </div>
          
          <!-- <p class="mt-4 text-base">{{ $t('contact.onlyTakeaway')}}</p> -->
        </div>
        <!-- <div class="mt-4 px-1 text-lg">
          <div class="flex justify-between items-start">
            <div>
              <p class="font-serif text-lg inline-block">{{$t('contact.delivery')}}</p>
              <span class="text-base block">{{ $t('menu.deliveryNote') }}</span>
             </div>
            
            <p class="text-right font-serif text-lg inline-block flex-shrink-0">12:30 - 14:30<br/>18:30 - 22:30</p>
          </div>

          <div>
             <span class="mr-2 px-3 py-1 bg-salmon-100 font-sans tracking-wider uppercase text-white text-lg text-center rounded-full">{{ $t('free') }}</span>
            
           
          </div>
          <div class="sm:space-x-4">
            <h2 class=" font-serif text-lg inline-block">12:30 - 14:30 · 18:30 - 22:30</h2>

            <h2 class="ml-8 sm:ml-0 text-salmon-100 uppercase font-sans text-lg inline-block">18:30 - 22:30</h2>
          </div>
          <span class="text-base sm:hidden">** {{ $t('menu.deliveryNote') }}</span>
        </div> -->
      </div>
      <div>
        <div class="flex items-center">
          <hr class="border-salmon-100 flex-1" />
          <h1 class="px-4 font-serif capitalize text-salmon-100 text-2xl text-center flex-none">{{ $t('nav.contact')}}</h1>
          <hr class="border-salmon-100 flex-1" />
        </div>
        <div class="mt-4 px-1">
          <div class="flex flex-col sm:flex-row sm:justify-between sm:items-center">
            <div class="space-y-2 mb-2 sm:mb-0">
              <a
                href="tel:+390513549896"
                class=" tracking-wide font-sans block"
              >
                <i class="fas fa-phone-alt h-8 mr-6"></i>
                <div class="flex-col inline-flex">
                  <span class="sm:inline hover:underline">+39 051 3549896</span>
                  <!-- <small class="font-medium">negli orari di apertura</small> -->
                </div>
              </a>
              <a
                href="tel:+393928198654"
                class=" tracking-wide font-sans block"
              >
                <i class="fas fa-phone-alt h-8 mr-6"></i>
                <span class=" sm:inline hover:underline">+39 392 8198654</span>
              </a>
            </div>

            <a
              href="mailto:ilcantucciodibologna@gmail.com"
              class="tracking-wide  font-sans block "
            >
              <i class="fas fa-envelope h-8 mr-6"></i>
              <span class=" sm:inline hover:underline">ilcantucciodibologna@gmail.com</span>
            </a>
          </div>
        </div>
        <div class="hidden lg:block lg:mt-48 xl:mt-56 px-1">
          <h4 class="text-salmon-100 text-5xl font-serif italic">...e vedi che ti mangi!</h4>
        </div>
      </div>
    </div>
    <div class="lg:w-1/2 mt-2 md:mt-0">
      <div class="flex items-center">
        <hr class="border-salmon-100 flex-1" />
        <h1 class="px-4 font-serif capitalize text-salmon-100 text-2xl text-center flex-none">{{ $t('contact.where')}}</h1>
        <hr class="border-salmon-100 flex-1" />
      </div>
      <!-- <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3384.4963429438285!2d11.342203868988317!3d44.49444464488041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2b9694d23bf4371d!2sIl%20Cantuccio!5e0!3m2!1sit!2sit!4v1603909591617!5m2!1sit!2sit"
        class="w-full border-white border-4 px-1 mt-4"
        style="height:60vh"
        aria-hidden="false"
        tabindex="0"
      ></iframe> -->
      <Map class="mt-4 px-1" />
      <div class="text-center text-lg mt-3 tracking-wider">
        <a
          href="https://goo.gl/maps/vYJcixoDNgqatGnk8"
          class="hover:underline"
        >Via Volturno 4, Bologna</a>
      </div>
    </div>

  </div>
</template>

<script>
import Map from "@/components/Map.vue";
export default {
  name: "ContactUs",
  components: {
    Map,
  },
};
</script>