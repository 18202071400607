<template>
  <div>
    <!-- <span class="glf-button" data-glf-cuid="30bfd71b-08b5-451c-83a6-14b16e44ad59"
      data-glf-ruid="062ed957-e1ed-45c0-9c7a-1da02428aada">See MENU & Order</span> -->
    <span
      style="display: flex; background-image: unset; font-family: system-ui, -apple-system, sans-serif; box-shadow:unset; text-shadow:unset; transition: unset; padding-top:.41em; padding-bottom: .41em;  text-transform:uppercase; font-weight:700; letter-spacing:0.1em; color: #f2ede1!important; background-color: rgba(197, 48, 48, var(--bg-opacity));  padding-left: 0.5rem; padding-right: 0.5rem;  border-radius: 0.375rem;"
      class="glf-button reservation items-center" data-glf-cuid="30bfd71b-08b5-451c-83a6-14b16e44ad59"
      data-glf-ruid="062ed957-e1ed-45c0-9c7a-1da02428aada" data-glf-reservation="true">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="h-6 mr-2">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
      </svg>
      {{ $t('home.book') }}</span>
  </div>
</template>

<script>
export default {
  name: "Book",
  components: {
  },
  mounted() {
    let gloriaFoodScript = document.createElement('script')
    gloriaFoodScript.setAttribute('src', 'https://www.fbgcdn.com/embedder/js/ewm2.js')
    gloriaFoodScript.setAttribute('defer', 'defer')
    gloriaFoodScript.setAttribute('async', 'async')
    document.head.appendChild(gloriaFoodScript)
  },
};
</script>