<template>
  <div class="lg:container lg:mx-auto lg:rounded-md bg-salmon-100 py-1">
      <span class="container mx-auto px-4 text-white text-xl block text-center space-x-2">
        <span class="bg-beige text-salmon-100 px-2 rounded-md">{{ $t('home.new') }}</span>
        <span class="block md:inline">{{ $t('home.message') }}</span>
        <!-- <span class="">12:30-14:30 · 18:30-22:30</span> -->
        <!-- <span class="">({{ $t('menu.deliveryNote') }})</span> -->
      </span>
    </div>
</template>

<script>
export default {
    name: 'MessageBar'
}
</script>
