<template>
  <div>
    <div class="flex flex-col lg:flex-col-reverse">
      <!-- <MessageBar /> -->
      <Header />
    </div>
    <div class="">
      <Vocabulary />

    </div>
    <div class="container mx-auto">
      <ContactUs id="contact" />
      <Footer />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import MessageBar from "@/components/MessageBar.vue";
import Header from "@/components/HomeHeader.vue";
//import VueGallery from "vue-gallery";
import Footer from "@/components/Footer.vue";
import ContactUs from "@/components/ContactUs.vue";
import Vocabulary from "@/components/Vocabulary.vue";

export default {
  name: "Homepage",
  components: {
    Header,
    //  Gallery: VueGallery,
    Footer,
    // MessageBar,
    ContactUs,
    Vocabulary
  },
  data: function () {
    return {
      images: [
        "/img/dish.jpg",
        "/img/dish1.jpg",
        "/img/dish2.jpeg",
        "/img/dish3.jpeg",
      ],
      index: 1,
    };
  },
};
</script>

<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>