<template>
  <div class="mt-4 md:mt-0 lg:mb-8">
    <div class="fish h-1/4-screen md:h-2/5-screen lg:h-auto flex items-center justify-center">
      <div class="flex flex-col items-center">
        <h1
          class="text-lg sm:text-3xl font-serif px-2 tracking-widest text-center font-semibold uppercase text-red-700 bg-beige">
          {{ $t('home.header') }}
        </h1>
        <div class="flex flex-col lg:flex-row space-y-3 lg:space-y-0 items-center mt-6 lg:space-x-3">
          <div class="flex items-center space-x-4">
            <a href="tel:+393928198654" style="height:42px;"
              class="font-bold tracking-wide text-beige font-sans bg-salmon-100 hover:bg-salmon-50 px-2 rounded-md flex items-center">
              <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                class="px-1 py- h-10">
                <path
                  d="M4.72.5H2.5a2 2 0 00-2 2v2c0 5.523 4.477 10 10 10h2a2 2 0 002-2v-1.382a1 1 0 00-.553-.894l-2.416-1.208a1 1 0 00-1.396.578l-.297.893a1.21 1.21 0 01-1.385.804A6.047 6.047 0 013.71 6.547a1.21 1.21 0 01.804-1.385l1.108-.37a1 1 0 00.654-1.19L5.69 1.257A1 1 0 004.72.5z"
                  stroke="currentColor"></path>
              </svg>
              <span class="uppercase ml-2 tracking-widest text-lg">{{ $t('home.callUs') }}</span>
            </a>
          </div>
          <Book /> 
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Book from './Book.vue';

export default {
  name: "HomeHeader",
  components: {
    Book
  },
  props: {
    msg: String,
  }
};
</script>
