<template>
  <figure
    v-lazyload
    :class="wrapperClass"
  >
    <img
      :class="imageClass"
      :data-url="source"
      :alt="alt"
    >
  </figure>
</template>

<script>
export default {
  name: "ImageItem",
  props: {
    source: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    wrapperClass: {
      type: String,
      required: false,
    },
    imageClass: {
      type: String,
      required: false,
    },
  },
};
</script>

<style>
</style>