<template>
  <div class=" my-8 px-6 md:px-0 w-full border-t border-salmon-100">
    <div class="flex justify-center items-center space-x-4 mt-8">
      <a href="https://www.facebook.com/cantucciobo" target="_blank">
        <img
          loading="lazy"
          class="h-6 hover:opacity-50"
          src="@/assets/png/f_logo2.png"
        />
      </a>
      <a href="https://instagram.com/il_cantucciobo" target="_blank">
        <img
          loading="lazy"
          class="hover:opacity-50"
          style="height: 1.45rem;"
          src="@/assets/png/instagram.png"
        />
      </a>
    </div>
    <div class="mt-4 text-center text-sm sm:text-base">
      <p>
        <span class="text-lg">©</span> 2020 Degustibus srls · P. IVA 03823751205
      </p>
      <span>
        Designed by
        <a
          class="hover:text-salmon-100"
          target="_blank"
          href="mailto:r.monticelli@outlook.it"
        >
          Riccardo Monticelli
        </a>
      </span>
      <span class="hidden md:inline"> · </span>
      <span class="block md:inline">
        Graphic concept by
        <a
          class="hover:text-salmon-100"
          target="_blank"
          href="http://nicolaleck.com/"
        >
          Nicola Leck
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
